/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:6b946439-f55a-4d6d-b69f-4a4e48472e66",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_70bmL721z",
    "aws_user_pools_web_client_id": "3061lm42b3p4ijfkoc4n0lmlra",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://bgmx4nzh6nc3tiugld5ytu4kmu.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-6fzilxlhazdnfegvanr3w4oksy",
    "aws_cloud_logic_custom": [
        {
            "name": "confirmOrder",
            "endpoint": "https://m07bm7eqxj.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "AdminQueries",
            "endpoint": "https://0cvjgx48dh.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "confirmOrderZsm",
            "endpoint": "https://tiufijtv6h.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "confirmOrderSchein",
            "endpoint": "https://o54anoex19.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "confirmOrderCare",
            "endpoint": "https://xxfhg6ptmk.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "confirmOrderFittr",
            "endpoint": "https://cv642qufrb.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "confirmOsaOrderFittr",
            "endpoint": "https://uejdknooal.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "confirmOsbOrderFittr",
            "endpoint": "https://nevazv22d7.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "confirmOsbOrderCare",
            "endpoint": "https://7y72kfj1n4.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "fittrapp-storage12052-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_mobile_analytics_app_id": "be1f301c177148aa9384d1812911b52c",
    "aws_mobile_analytics_app_region": "eu-west-1",
    "predictions": {
        "convert": {
            "translateText": {
                "region": "eu-west-1",
                "proxy": false,
                "defaults": {
                    "sourceLanguage": "nl",
                    "targetLanguage": "de"
                }
            }
        },
        "interpret": {
            "interpretText": {
                "region": "eu-west-1",
                "proxy": false,
                "defaults": {
                    "type": "LANGUAGE"
                }
            }
        }
    }
};


export default awsmobile;
